function App() {
  return (
    <div style={styles.app}>
      <div style={styles.title}>gluco.ai</div>
      <div style={styles.subTitle}>Coming Soon</div>
    </div>
  );
}

const styles = {
  app: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    fontFamily: "Roboto, sans-serif",
  },
  title: {
    fontSize: "15vw",
  },
  subTitle: {
    fontSize: "5vw",
    color: "#d3d3d3",
  },
};

export default App;
